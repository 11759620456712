@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
body,
html,
#root {
	margin: 0;
	padding: 0;
} */

:root {
	--color-primary: #d2413e;
}

* {
	box-sizing: border-box;
}

.App {
	padding: 20px;
	background-color: #ffd6a3;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ffebbf'/%3E%3Cstop offset='1' stop-color='%23ffebbf' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ffc3bf'/%3E%3Cstop offset='1' stop-color='%23ffc3bf' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ffe2db'/%3E%3Cstop offset='1' stop-color='%23ffe2db' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ffd6a3'/%3E%3Cstop offset='1' stop-color='%23ffd6a3' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23fff8db'/%3E%3Cstop offset='1' stop-color='%23fff8db' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ffdbea'/%3E%3Cstop offset='1' stop-color='%23ffdbea' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
	background-attachment: fixed;
	background-size: cover;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	font-family: 'IBM Plex Sans', sans-serif;
}

p {
	font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 1em 0;
}

h2,
h3,
h4,
h5,
h6 {
	color: rgb(88, 88, 88) !important;
}

.feedback-title,
h1 {
	font-size: 40px;
	background: -webkit-linear-gradient(135deg, #dd7171, #ffd4b4);
	-webkit-background-clip: text;
	font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
	-webkit-text-fill-color: transparent;
}

input[type='radio'] {
	border: 0px;
	width: 20px;
	cursor: pointer;
	height: 2em;
}

.page-center {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* TRANSITION */

.loading-screen-appear {
	opacity: 0;
}

.loading-screen-appear-active {
	opacity: 1;
	transition: opacity 1s cubic-bezier(0.15, 1, 0.3, 1);
}

.feedback-form-enter {
	opacity: 0;
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
}

.feedback-form-enter-active {
	opacity: 1;
	-webkit-transform: scale(1);
	        transform: scale(1);
	transition: opacity 1s cubic-bezier(0.15, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.15, 1, 0.3, 1);
	transition: opacity 1s cubic-bezier(0.15, 1, 0.3, 1), transform 1s cubic-bezier(0.15, 1, 0.3, 1);
	transition: opacity 1s cubic-bezier(0.15, 1, 0.3, 1), transform 1s cubic-bezier(0.15, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.15, 1, 0.3, 1);
}
.feedback-form-exit {
	opacity: 1;
}

.feedback-form-exit-active {
	opacity: 0;
	transition: opacity 1s cubic-bezier(0.15, 1, 0.3, 1);
}

.thank-you-enter {
	opacity: 0;
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
}

.thank-you-enter-active {
	opacity: 1;
	-webkit-transform: scale(1);
	        transform: scale(1);
	transition: opacity 1s cubic-bezier(0.15, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.15, 1, 0.3, 1);
	transition: opacity 1s cubic-bezier(0.15, 1, 0.3, 1), transform 1s cubic-bezier(0.15, 1, 0.3, 1);
	transition: opacity 1s cubic-bezier(0.15, 1, 0.3, 1), transform 1s cubic-bezier(0.15, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.15, 1, 0.3, 1);
}

